import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const GdprViolations = () => {
  return (
    <Article>
      <Article.Header>Known issues</Article.Header>
      <Article.Body>
        <Article.Section>
          <Article.SubHeader>Search filtered to only show dbEntries</Article.SubHeader>
          <Typography>
            In PIA v 1.0 the search would automatiaclly filter to dbEntry. This feature was removed with 
            the release of PIA v 2.0 in the summer of 2023. If you still run into this issue, it could be 
            because you are using an old url.  If this is the case, the problem should be fixed by removing 
            the filter and using the new url.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Error message when downloading files</Article.SubHeader>
          <Typography>
             Sometimes you get an error message trying to download a file, normally .msg-file (Outlook email), 
             saying the file type could potentially harm your computer. This is a security measure from corporate 
             security that we cannot do anything about. But there is a work around. Browse the curser over the file 
             in the download window in your web browser. An ellipsis (three dot) menu should occur, similar to the 
             ellipsis menu in SharePoint. Click on that and choose "keep", and the file will be downloaded.
          </Typography>
          <Article.Gallery>
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
          </Article.Gallery>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default GdprViolations 