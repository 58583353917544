import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'
import { List } from '@equinor/eds-core-react'

const EroomJsonFiles = () => {
  return (
    <Article>
      <Article.Header>eRoom JSON Files</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            JSON is a file format, just like word, pdf or excel. In PIA you can find JSON files that were stored as such
            in eRoom, but you may also find JSON files that were created to store eRooms metadata. You can see an overview
            of this content in the table below.
          </Typography>
          <Article.Gallery>
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
          </Article.Gallery>
          <Article.Caption>eRoom content types</Article.Caption>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>How to open JSON files</Article.SubHeader>
          <Typography>
            If you are using a windows operating system, we recommend using the windows native application “Notepad” 
            to open JSON files. If you are using a MAC, we recommned “Notes”. Right click the file and select “Open with” -`&gt;` “Notepad”.
          </Typography>
          <Article.Gallery>
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
          </Article.Gallery>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Links</Article.SubHeader>
          <Typography>
            Sometimes your only reference is an old link from eRoom. One can get a lot of information from that link such as project 
            number, what type of eRoom (e.g. project eRoom or a contract eRoom) and file name.
          </Typography>
          <Typography>
            In the example to the right, we can see that project number is PM641 and eRoom type is project team eRoom.
            We can easily navigate down to correct project and eRoom through the navigation panel.
            To narrow it down further we can search for whole or part of the file name in the search field and in this case, it narrows 
            it down to the one file we were looking for.
          </Typography>
          <Article.Gallery>
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
          </Article.Gallery>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default EroomJsonFiles