import React, { Component } from 'react'
import AppContext from './utils/api/AppContext'
import { APIClient } from './utils/api/apiClient'
import withAuth, { AuthComponentProps } from './utils/auth/AuthProvider'
import UserContext, { User } from './utils/auth/UserContext'
import { ViewContextProvider } from './contexts/ViewContext'
import { APPLICATION_INSIGHT_KEY } from './config'

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import Content from './Content'
import { AccountInfo } from '@azure/msal-browser'
import ErrorDisplay from './components/Error/ErrorDisplay'
import { LicenseManager } from 'ag-grid-enterprise'

const browserHistory = createBrowserHistory()
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APPLICATION_INSIGHT_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
})
appInsights.loadAppInsights()

const suppressConsoleError = (shouldBeHidden: (message: string) => boolean) => {
  const err = console.error
  console.error = (message?:any, ...optionalParams: any[]) => {
    if (typeof message === "string" && shouldBeHidden(message)) {
      return
    }
    err(message, ...optionalParams)
  }
}
    
class App extends Component<AuthComponentProps> {
  apiClient = new APIClient(() => this.props.getAccessToken())


  render() {

    // suppressConsoleError((m) => m.startsWith("Warning: Invalid aria prop"))
    // suppressConsoleError((m) => m.startsWith("*"))
    
    const agGridLicenseKey = 'CompanyName=Equinor ASA,LicensedGroup=Fusion,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=20,LicensedProductionInstancesCount=2,AssetReference=AG-039379,SupportServicesEnd=1_May_2024_[v2]_MTcxNDUxODAwMDAwMA==c33829717f0c1c0465f878ce4d5c457d'
    LicenseManager.setLicenseKey(agGridLicenseKey)

    const accountInfo: AccountInfo | null = this.props.user
    const roles = accountInfo?.idTokenClaims['roles'] ?? []
    const user: User | undefined =
            accountInfo === null ? undefined 
              : {
                username: accountInfo.username, 
                isStageRole: roles.includes('StageRole'), 
                isDeleteRole: roles.includes('DeleteRole'),
              }
    return (
      <ViewContextProvider>
        <AppContext.Provider value={{ apiClient: this.apiClient }}>
          <UserContext.Provider value={user}>
            {this.props.authenticated === true && <Content logOut={this.props.logout} />}
            {this.props.error !== null && <ErrorDisplay error={{ originalError: this.props.error }} />}
          </UserContext.Provider>
        </AppContext.Provider>
      </ViewContextProvider>
    )
  }
}

export default withAITracking(reactPlugin, withAuth(App))
