import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'
import { Table } from '@equinor/eds-core-react'

const FolderNavigationInfo = () => {
  return (
    <Article>
      <Article.Header>Filter</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            Columns in the search result table marked with the “menu icon” may be used to filter the results.    
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.Gallery>
            <img src="https://placehold.co/600x200" alt="eRoom content types" />
          </Article.Gallery>
        </Article.Section>

        <Article.Section>
          <Article.SubHeader>Filetype</Article.SubHeader>
          <Typography>
            “Filetype” is a combination of the file properties: “file extension” and “itemType”. 
          </Typography>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell>
                    Syntax
                </Table.Cell>
                <Table.Cell>
                    Example
                </Table.Cell>
                <Table.Cell>
                    Description
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                    to do:
                </Table.Cell>
                <Table.Cell>
                    add file type info
                </Table.Cell>
                <Table.Cell>
                   when filtering on filetype is updated 
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default FolderNavigationInfo