import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'
import { List } from '@equinor/eds-core-react'

const FileSideBar = () => {
  return (
    <Article>
      <Article.Header>File Sidebar</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            Once you find a file you're interested in, you can click on the corresponding tablerow to open the file sidesheet.
            Here you will be able to download the file and view more information about it. 
            The File properties- displays metadata surrounding the file itself, while the Folder properties displays metadata 
            about the folder/dbEntry it is stored in. At the very bottom of the sidebar, you will find an overview of the folder 
            where it is stored and it’s sibling files.    
          </Typography>
          
        </Article.Section>
        <Article.Section>
          <Article.Gallery>
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
          </Article.Gallery>
          <Article.Caption>eRoom content types</Article.Caption>
        </Article.Section>
        <Article.Section>
          <Typography>
            Once you find a file you're interested in, you can click on the corresponding tablerow to open the file sidesheet.
            Here you will be able to download the file and view more information about it.           
          </Typography>
          <Typography>
            The filesheet currently consists of three seperate views: Preview-, information- and folder view.
          </Typography>
          <List>
            <List.Item>The preview view is currently only available for eRoom comments (also called “topics”) </List.Item>
            <List.Item>The information view displays metadata regarding the file itself and the original eRoom folders it was stored in. </List.Item>
            <List.Item>The folder view let’s you view and download sibling-files. These are files that were stored in the same folder as the selected file. </List.Item>
          </List>
          <Article.Spacer />
          <Typography>
            If the user does not have access to the file, she will get an error message.           
          </Typography>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default FileSideBar