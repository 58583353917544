import React from 'react'
import Article from '../Components/Article'
import { Typography, Button } from '@equinor/eds-core-react'

const WhoToContact = () => {
  return (
    <Article>
      <Article.Header>Who can I contact?</Article.Header>
      <Article.Body>
        <Article.Section>
          <Article.SubHeader>Support</Article.SubHeader>
          <Typography>
            If you run into technical problems you can contact support in Service Now.          
          </Typography>
          <Article.Spacer />
          <Button>Create Support Ticket in Service Now</Button>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Project owner</Article.SubHeader>
          <Typography>
            If you have questions or improvemt suggestions regarding PIA , you can contact the current project owner.          
          </Typography>
          <Article.Spacer />
          <Button>Send email to PO</Button>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default WhoToContact