export const numberOfOptionsToFetch = 10
export const numberToGetAllOptionsInFacets = 0
export const NAVIGATION_FILTER_NAMES_IN_ORDER = ['project', 'eRoom', 'l0', 'l1', 'l2', 'l3', 'l4', 'l5', 'l6', 'l7', 'l8']
export const ITEMS_PER_PAGE = 100
export const MAX_DOWNLOAD_SIZE = 500 // MB
export const DEFAULT_SHEET_WIDTH = 500
export const FACET_ITEMS_TO_ALWAYS_QUERY_FOR = {
  classification: numberOfOptionsToFetch,
  creator: numberOfOptionsToFetch,
  fileExtension: numberOfOptionsToFetch,
  itemType: numberOfOptionsToFetch,
}
