import React from 'react'
import Article from '../Components/Article'
import { Table, Typography, List } from '@equinor/eds-core-react'

const WhatIsPia = () => {
  return (
    <Article>
      <Article.Header>What is PIA?</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
          Project Information Archive (PIA) is a search and navigation tool for data from eRoom.
          </Typography>
          <Typography>
          Some content from eRooms that we're not files, have been stored in json formats (see more on json below).
          </Typography>
          <Typography>
          PIA is developed by Equinor and is used internally in the company. It is not available for external users.
          </Typography>
        </Article.Section>

        {/* 
        <Article.Section>
          <Article.Gallery>
            <img src="https://placehold.co/600x400" alt="eRoom content types" />
            <img src="https://placehold.co/600x400" alt="eRoom content types" />
          </Article.Gallery>
          <Article.Caption>eRoom content types</Article.Caption>

          <Article.SubHeader>Search</Article.SubHeader>
          <Article.Gallery>
            <img src="https://placehold.co/600x400" alt="Search" />
          </Article.Gallery>
        </Article.Section>

        */}
      </Article.Body>
    </Article>
  )
}

export default WhatIsPia