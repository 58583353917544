import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '@equinor/eds-core-react'
import { folder } from '@equinor/eds-icons'
import { Icon } from '@equinor/eds-core-react'
import { Tooltip } from '@equinor/eds-core-react'

import { ViewContext } from '../../../contexts/ViewContext'

const NavButton = styled(Button)`
    margin: 10px 0;
    align-self: end;
`

const FolderButton = () => {
  const {
    setSideBarIsOpen,
    setSideBarMode,
    setLastSideBarMode,
    sideBarIsOpen,
    lastSideBarMode
  } = useContext(ViewContext)
  
  const [initialRender, setInitialRender] = useState(true)
  
  const openFolderSideBar = useCallback(() => {
    setSideBarMode('navigate')
    setSideBarIsOpen(true)
    setLastSideBarMode('navigate')
  }, [setSideBarMode, setSideBarIsOpen, setLastSideBarMode])
  
  useEffect(() => {
    if (initialRender && lastSideBarMode === 'start') {
      openFolderSideBar()
      setInitialRender(false)
    }
  }, [initialRender, lastSideBarMode, openFolderSideBar])
  
  const toggleFolderSidebar = () => {
    if (!sideBarIsOpen || lastSideBarMode === 'filter') {
      openFolderSideBar()
    } else {
      setSideBarIsOpen(false)
    }
  }

  return (
    <Tooltip title="Navigate folders" placement="bottom">
      <NavButton style={{ zIndex: 100 }} variant="ghost" onClick={toggleFolderSidebar}>
        <Icon data={folder} size={18} />
      </NavButton>
    </Tooltip>
  )
}

export default FolderButton
