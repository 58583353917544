import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'
import { List } from '@equinor/eds-core-react'

const FileSideBar = () => {
  return (
    <Article>
      <Article.Header>Download</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            You download files one by one, or you can downlaod multiple files at once by using the “bulk download” tool.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Single Download</Article.SubHeader>
          <Typography>
                To download a single file you can click the file in the search result tabel and press the "download" 
                button in the file sidebar. While you're in the sidebar you can view content from the same folder, by 
                scrolling down to the folder overview. Here you can download sibling files by pressing the download 
                icon (icon here) next to the filename.
          </Typography>
          <Article.Gallery>
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
          </Article.Gallery>
          <Article.Caption>eRoom content types</Article.Caption>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Bulk Download</Article.SubHeader>
          <Typography>
            To download multiple files click the "bulk download" button above the search result table and select files 
            using the checkboxes. Once you have seleted the files you want, simply press "download" to download a zip of 
            these files. You also rename the zip filename if you wish.          
          </Typography>
          <Article.Gallery>
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
          </Article.Gallery>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Export to Excel</Article.SubHeader>
          <Typography>
            If you want to see the current search result in excel-format, you may use the “export to Excel” tool. Note 
            that this will only export the current page you are on, and not the whole search result.         
          </Typography>
          <Article.Gallery>
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
            <img src="https://placehold.co/600x300" alt="eRoom content types" />
          </Article.Gallery>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default FileSideBar