import React, { useEffect, useContext } from 'react'
import CenteredStarProgress from '../../components/CenteredStarProgress'
import SearchBar from '../../components/inputs/SearchBar'
import Pagination from '../../components/Pagination'
import SearchResultsView, { VerticalToolbar } from './searchPage/SearchResultsView'
import StickyFooter from '../../components/StickyFooter'
import styled from 'styled-components'
import { SearchDocument } from '../../utils/api/payloads'
import { useSearchStateContext } from '../../contexts/SearchStateContext'
import { ITEMS_PER_PAGE } from '../../utils/constants'
import { useUpdate } from '../../utils/hooks'
import { ViewContext } from '../../contexts/ViewContext'
import FolderButton from '../../components/Sidebars/Buttons/FolderButton'
import FolderNavigation from '../../components/Sidebars/FolderNavigation/FolderNavigation'
import Intro from '../../components/Intro'
import StagedDocsView from '../adminPage/StagedDocsView'
import { useUrlSearchState } from '../../utils/hooks'

interface Props {
    decreasePage: (isInAdmin?: boolean) => void
    increasePage: (isInAdmin?: boolean) => void
    clearSearch: (isInAdmin?: boolean) => void
    updateSearchString: (text: string, isInAdmin?: boolean) => void
    documents: SearchDocument[]
    isSearching: boolean
    searchForDocs: (isInAdmin: boolean) => void
    isInAdmin: boolean}
const FrontContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const BodyContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
`

const TableContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: calc(100vh - 60px);
    width: 100vw;
    min-width: 900px;
    min-height: 600px;
    align-items: stretch;
`

export const SearchRoute = ({
  decreasePage,
  increasePage,
  clearSearch,
  updateSearchString,
  documents,
  isSearching,
  searchForDocs,
  isInAdmin,
}: Props) => {
  const { searchState } = useSearchStateContext()
  const [, noSearchMade] = useUrlSearchState()

  const { numTotalItems, levelText } = useContext(ViewContext)
  const { currentPage, searchString } = searchState

  const [placeHolder, setPlaceHolder] = React.useState<string>('Search for documents')

  useEffect(() => {
    levelText && !isInAdmin ? setPlaceHolder(`Search for documents in ${levelText}`) : setPlaceHolder('Search for documents')
  }, [levelText, isInAdmin])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])


  useUpdate(() => {
    searchForDocs(isInAdmin)
  }, [searchState])


  const onSearchPress = (text: string) => {
    updateSearchString(text, isInAdmin)
  }

  return (
    <Wrapper> 
      <FolderNavigation 
        clearSearch={clearSearch} 
        isInAdmin={isInAdmin}
      />
      <BodyContainer id="bodyContainer">
        <div>
          <VerticalToolbar id="verticalBar">
            <FolderButton />
          </VerticalToolbar>
          <SearchBar placeholder={placeHolder} onSearch={onSearchPress} value={searchString} />
        </div>
        <TableContainer id="tableContainer">
          {isSearching 
            ? <CenteredStarProgress style={{ marginTop: 50 }} /> 
            : noSearchMade() && !isInAdmin ? 
              <FrontContainer>
                <Intro />
              </FrontContainer> : (
                <>
                  {
                    isInAdmin ? (
                      <StagedDocsView 
                        documents={documents} 
                        redoSearch={() => searchForDocs(isInAdmin)}
                        isInAdmin={isInAdmin}
                      >
                        <Pagination
                          currentPage={currentPage + 1}
                          numPages={Math.ceil(numTotalItems / ITEMS_PER_PAGE)}
                          onPrevClick={() => decreasePage(true)}
                          onNextClick={() => increasePage(true)}
                        />
                      </StagedDocsView>
                    ) : (
                      <SearchResultsView
                        documents={documents}
                        redoSearch={() => searchForDocs(isInAdmin)}
                        isInAdmin={isInAdmin}
                      >
                        <Pagination
                          currentPage={currentPage + 1} 
                          numPages={Math.ceil(numTotalItems / ITEMS_PER_PAGE)}
                          onPrevClick={() => decreasePage(isInAdmin)}
                          onNextClick={() => increasePage(isInAdmin)}
                        />
                      </SearchResultsView>
                    )
                  } 
                </>
              )}
        </TableContainer>

        {!isSearching && numTotalItems === undefined && <StickyFooter />}
      </BodyContainer>
    </Wrapper>
  )
}

export default SearchRoute

