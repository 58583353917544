import React from 'react'

interface Props {
    size: number;
    fontColor: string;
    text: string; // 3 characters maximum
    fillColor: string; // text box background color
}

export const FileIcon = ({ size, fontColor, text, fillColor }: Props) => {
  const sanitizedText = text.length > 3 ? text.substring(0, 3) : text // Ensure text is 3 characters max
  const viewBoxWidth = 19.32
  const viewBoxHeight = 20
  const textX = viewBoxWidth / 2 
  const textY = 15.63 

  return (
    <svg width={size} height={size} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} xmlns="http://www.w3.org/2000/svg">
      <style>
        {`
          .cls-1 {
            font-family: 'AppleSDGothicNeo-Bold-KSCpc-EUC-H', 'Apple SD Gothic Neo';
            font-size: 7.59px;
            font-weight: 700;
            fill: ${fontColor};
            text-anchor: middle; // Center text horizontally
          }
          .cls-2 {
            fill: gray;
            fill-rule: evenodd;
          }
          .cls-2, .cls-3 {
            stroke-width: 0;
          }
          .cls-3 {
            fill: ${fillColor};
          }
        `}
      </style>
      <path className="cls-2" d="m3.66,0h8l6,6v12c0,1.1-.9,2-2,2H3.65c-1.1,0-1.99-.9-1.99-2V2c.01-1.1.9-2,2-2Zm0,2v16h12V7h-5V2H3.66Z"/>
      <rect className="cls-3" y="8.61" width={viewBoxWidth} height="8.4"/>
      <text className="cls-1" x={textX} y={textY} transform="scale(.96 1)">{sanitizedText}</text>
    </svg>
  )
}

export default FileIcon
