import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const FolderNavigationInfo = () => {
  return (
    <Article>
      <Article.Header>Folder Navigation</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            Use the folder navigator on the lefthand side of the screen to select a project.  
            When a project is selected, you may further navigate to specific sub-folders through 
            the folder hierarchy. (Fig 1)          
          </Typography>
          <Typography>
          Some content from eRooms that we're not files, have been stored in json formats (see more on json below).
          </Typography>
          <Typography>
          PIA is developed by Equinor and is used internally in the company. It is not available for external users.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.Gallery>
            <img src="https://placehold.co/600x400" alt="eRoom content types" />
            <img src="https://placehold.co/600x400" alt="eRoom content types" />
          </Article.Gallery>
          <Article.Caption>eRoom content types</Article.Caption>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default FolderNavigationInfo