import React from 'react'
import { AccountInfo, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'
import { config } from '../../config'

export interface AuthComponentProps {
    error: Error
    authenticated: boolean
    user: AccountInfo
    login: Function
    logout: Function
    getAccessToken: Function
    setError: Function
}
 
interface AuthProviderState {
    error: Error
    authenticated: boolean
    user: AccountInfo
}

export default function withAuth<T extends React.Component<AuthComponentProps>>(
  Wrapped: new (props: AuthComponentProps, context?: any) => T
): React.ComponentClass {
  class WithAuth extends React.Component<any, AuthProviderState> {
    private userAgentApp: PublicClientApplication
    private scopes = ['api://51254961-ec04-4635-9a2e-b60ef790568e/user_impersonation']

    constructor(props: any) {
      super(props)
      this.state = {
        error: null,
        authenticated: false,
        user: null,
      }

      this.userAgentApp = new PublicClientApplication({
        auth: {
          clientId: config.appId,
          redirectUri: window.location.origin,
          authority: config.authority,
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: false,
        },
      })
    }

    async componentDidMount() {
      await this.userAgentApp
        .handleRedirectPromise()
        .then(tokenResponse => {
          if (tokenResponse !== null) {
            console.log('Coming back from an auth redirect')
          }
        })
        .catch((errorResponse: Error) => {
          console.log(`Error either in the auth library or from the auth server`, errorResponse)
          const error = errorResponse
          if (this.isInteractionRequired(error)) {
            error.name = 'Interaction required'
            error.message = 'Please apply for access to this application. '
            error.stack = undefined
          }
          this.setErrorMessage(error)
        })

      const accounts = this.userAgentApp.getAllAccounts()
      if (this.state.error !== null) {
        console.log('Error: ' + this.state.error.message)
      } else if (accounts.length > 0) {
        this.setState({
          ...this.state,
          authenticated: true,
          user: accounts[0],
        })
      } else {
        console.log('No accounts')
        this.login()
      }
    }

    render() {
      return (
        <Wrapped
          error={this.state.error}
          authenticated={this.state.authenticated}
          user={this.state.user}
          login={() => this.login()}
          logout={() => this.logout()}
          getAccessToken={() => this.getAccessToken()}
          setError={(error: Error) => this.setErrorMessage(error)}
          {...this.props}
          {...this.state}
        />
      )
    }

    async login() {
      try {
        await this.userAgentApp.loginRedirect({
          scopes: this.scopes,
        })

        console.log('Logged in')
      } catch (err: any) {
        console.log('Error: ' + err.message)
        this.setState({
          authenticated: false,
          user: null,
          error: err,
        })
      }
    }

    logout() {
      this.userAgentApp.logout()
    }

    async getAccessToken() {
      try {
        const tok = await this.userAgentApp.acquireTokenSilent({ scopes: this.scopes, account: this.state.user })
        return tok.accessToken
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          await this.userAgentApp.acquireTokenRedirect({ scopes: this.scopes })
        } else {
          console.log('ERROR: ' + err)
          return null
        }
      }
    }

    setErrorMessage(error: Error): any {
      this.setState({
        error: error,
      })
    }

    isInteractionRequired(error: Error): boolean {
      if (!error.message || error.message.length <= 0) {
        return false
      }

      return (
        error.message.indexOf('consent_required') > -1 ||
                error.message.indexOf('interaction_required') > -1 ||
                error.message.indexOf('login_required') > -1
      )
    }
  }

  return WithAuth
}
