import React, {useState, useContext, useEffect} from "react"
import ModalContainer from "./ModalContainer"
import { bytesToMegabytes, generateZipName, getFileIcon } from "../../utils/helpers"
import { Typography, Table, Button, Input, Label, Icon } from "@equinor/eds-core-react"
import styled from "styled-components"
import { SearchDocument } from "../../utils/api/payloads"
import { ViewContext } from '../../contexts/ViewContext'
import { delete_multiple, delete_to_trash } from "@equinor/eds-icons"
import { useAppContext } from "../../utils/api/AppContext"



const Header = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    p {
        white-space: nowrap;
        padding-right: 10px;
    }

    div {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }
`

const FileTitle = styled(Table.Cell)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
`

const StyledTable = styled(Table)`
    width: 100%;
    border: 1px solid #e0e0e0;
    margin: 15px 0 25px 0;
`
const TableBody = styled(Table.Body)`
    max-height: 400px;
    overflow-y: auto;
`
const ZipDetails = styled.div`
    margin: 25px 0;
`

interface Props {
    docsChecked: SearchDocument[]
    uncheckAllRows: () => void
    downloadModalOpen: boolean
    setDownloadModalOpen: (isOpen: boolean) => void
    uncheckRow: (doc: SearchDocument) => void
}

const FilesToDownloadModal = ({ docsChecked, uncheckAllRows, downloadModalOpen, setDownloadModalOpen, uncheckRow }: Props) => {
  const { setDownloadMode, errors, setErrors } = useContext(ViewContext)
  const [zipName, setZipName] = useState<string>(generateZipName)
  const { apiClient } = useAppContext()

  useEffect(() => {
    if (docsChecked.length === 0) {
      setDownloadModalOpen(false)
    }
  }, [docsChecked])
    
  const handleCancel = () => {
    setDownloadModalOpen(false)
  }

  const handleOk = async () => {
    const indexKeys = docsChecked.map(doc => doc.indexKey)
    const postBody = {
      SearchIndexElementKeys: indexKeys,
      ZipFileName: zipName
    }

    setDownloadModalOpen(false)
    
    const info = {
      id: 'downloadInfo',
      title: `Downloading ${docsChecked.length} file(s)`,
      body: 'Please wait...',
      variant: 'default', 
    }
    setErrors({ ...errors, [info.id]: info })
    await apiClient.downloadMultiple(postBody).then(() => {

      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[info.id]
        return newErrors
      })

      setDownloadMode(false)
      uncheckAllRows()

      const success = {
        id: 'downloadSuccess',
        title: `Download complete`,
        body: 'The zip file is now available in your downloads folder.',
        variant: 'default', 
        timeOut: 3000
      }
      
      setErrors({ ...errors, [success.id]: success })
      
    }
    ).catch((error) => {
      console.log("Errow while downloading file: ", error)

      const errorInfo = {
        id: 'downloadError',
        title: `Error downloading files`,
        body: 'Please contact support if the problem persists. Error: ' + error.message,
        variant: 'danger', 
      }

      setErrors({ ...errors, [errorInfo.id]: errorInfo })
    })

  }

  let totalSize = 0
  if (docsChecked.length > 0) {
    docsChecked.forEach(doc => totalSize += bytesToMegabytes(doc.size))
  }

  const removeAllDocs = () => {
    uncheckAllRows()
    setDownloadModalOpen(false)
  }
    
  return (
    <ModalContainer
      okText="Download"
      cancelText="Cancel"
      title="Review selected files"
      onOk={handleOk}
      onCancel={handleCancel}
      isOpen={downloadModalOpen}
      setIsOpen={setDownloadModalOpen}
    >
      <Header>
        <div>
          <Typography variant="h6">{docsChecked.length} files selected</Typography>
          <Typography variant="h6">({Math.floor(totalSize * 100) / 100} MB)</Typography>
        </div>
        <Button variant="outlined" onClick={removeAllDocs}>
          <Icon data={delete_multiple} size={20} />
            Remove all
        </Button>
      </Header>
      <StyledTable>
        <Table.Head>
          <Table.Row>
            <Table.Cell>File name</Table.Cell>
            <Table.Cell>Size</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Head>
        <TableBody>
          {docsChecked.map(doc => (
            <Table.Row key={doc.indexKey}>
              <FileTitle>
                {getFileIcon(doc)}
                <Typography>{doc.title}</Typography>
              </FileTitle>  
              <Table.Cell>{Math.floor(bytesToMegabytes(doc.size) * 100) / 100} MB</Table.Cell>
              <Table.Cell>
                <Button variant="ghost_icon" onClick={() => uncheckRow(doc)}>
                  <Icon data={delete_to_trash} size={20} />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </TableBody>
      </StyledTable>
      <ZipDetails>
        <Label htmlFor="zipName" label="Zip folder name" />
        <Input id="zipName" value={zipName} onChange={(e) => setZipName(e.target.value)} />
      </ZipDetails>
    </ModalContainer>
  )
}

export default FilesToDownloadModal