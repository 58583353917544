import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const GdprViolations = () => {
  return (
    <Article>
      <Article.Header>Reviewing GDPR violations</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            If you have the administrator role in PIA, you are able to report GDPR violations found in files. 
            You can do this by selecting a file and clicking the “Report GDPR violations” button. This will 
            hide the files and add them to a list of files with reported GDPR violations. As an administrator 
            you can view this list in the page “Reported files”. This page is identical to the regular search 
            page, but only shows files where a GDPR violation has been reported. Here you have the option to 
            permanently delete files from PIA or restore them.
          </Typography>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default GdprViolations 