import React, { useState, useContext } from 'react'
import { ViewContext } from '../../../contexts/ViewContext'
import { useAppContext } from '../../../utils/api/AppContext'
import { SearchDocument } from '../../../utils/api/payloads'
import styled from 'styled-components'
import { Icon } from '@equinor/eds-core-react'
import { download } from '@equinor/eds-icons'
import { Button } from '@equinor/eds-core-react'
import { Tooltip } from '@equinor/eds-core-react'
import { formatBytes, formatDate, getFileIcon, getPageNumber } from '../../../utils/helpers'
import { ITEMS_PER_PAGE } from '../../../utils/constants'
import { getDirectoryUrl, getFolderParts, removeFiltersFromSearchParams } from '../../../utils/helpers'
import { useUrlSearchState } from '../../../utils/hooks'

const StyledP = styled.p`
    width: 100%;
    padding: 5px 0 5px 10px;
    margin: 0;
`

const TipRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > strong {
        margin-right: 15px;
    }
`

const File = styled.div`
    padding: 0px 10px 0px 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
	  border-bottom: 1px solid #e0e0e0;

    p {
      font-size: 0.875rem;
      
    }
    &:hover {
        background-color: #f0f0f0;
    }
    background-color: ${props => (props.highlighted ? '#e5faeb' : 'transparent')};
`

const FolderLink = styled.a`
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;     
    text-overflow: ellipsis;
`

interface FileElementProps {
    doc: SearchDocument
    highlighted?: boolean
    document: SearchDocument
    filePosition: number
    isDownloading: boolean
    setIsDownloading: (isDownloading: boolean) => void
}

const FileElement = ({ doc, highlighted, document, filePosition, isDownloading, setIsDownloading }: FileElementProps) => {
  const { errors, setErrors } = useContext(ViewContext)
  const { apiClient } = useAppContext()
  const [searchState] = useUrlSearchState()
  const fileIcon = getFileIcon(doc)

  const downloadFile = (selectedDoc: SearchDocument) => {
    setIsDownloading(true)
    apiClient
      .downloadFile(selectedDoc.indexKey, selectedDoc.name, selectedDoc.contentType)
      .catch(e => {
        const newError = {
          id: 'downloadError',
          title: 'Error when downloading',
          body: 'Please apply for access to this project',
          variant: 'danger',
        }
        console.error(e)
        setErrors({ ...errors, [newError.id]: newError })
      })
      .finally(() => {
        setIsDownloading(false)
      })
  }

  const handleFolderClick = (e) => {
    e.preventDefault() 

    const pageContainingFile = getPageNumber(ITEMS_PER_PAGE, filePosition )
    const folderParts = getFolderParts(document)
    const folderUrl = getDirectoryUrl(folderParts, removeFiltersFromSearchParams(searchState))

    sessionStorage.setItem('referredPage', pageContainingFile.toString())
    sessionStorage.setItem('docId', doc.indexKey)

    window.open(folderUrl, '_blank')
  }
  
  return (
    <Tooltip
      title={
        <div>
          <TipRow>
            <strong>Size:</strong>
            <span> {formatBytes(doc.size)}</span>
          </TipRow>
          <TipRow>
            <strong>Created:</strong>
            <span> {formatDate(doc.createDate)}</span>
          </TipRow>
          <TipRow>
            <strong>Creator:</strong>
            <span> {doc.creator}</span>
          </TipRow>
        </div>
      }
      placement="left"
    >
      <File 
        highlighted={highlighted}
      >
        {fileIcon}
        <FolderLink 
          onClick={handleFolderClick}
          target="_blank"
          rel="noreferrer">
          <StyledP>{doc.title}</StyledP>
        </FolderLink>
        <Button
          disabled={isDownloading}
          style={{ width: '52px' }}
          variant="ghost_icon"
          onClick={() => {
            downloadFile(doc)
          }}
        >
          <Icon data={download} size={20} />
        </Button>
      </File>
    </Tooltip>
  )
}

export default FileElement
