import React, { useContext, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { Breadcrumbs } from '@equinor/eds-core-react'
import { ViewContext } from '../../contexts/ViewContext'
import { useFilterOptionsContext } from '../../contexts/FilterOptionsContext'
import { useSearchStateContext } from '../../contexts/SearchStateContext'
import { removeEroomFromName, updatedNavigationSearchStatePart } from '../../utils/helpers'
import { NAVIGATION_FILTER_NAMES_IN_ORDER } from '../../utils/constants'
import CrumbElement from './CrumbElement'
import { tokens } from '@equinor/eds-tokens'
import { Link } from "react-router-dom"

const CrumbContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 80%;
    margin-bottom: 5px;
    margin-bottom: 5px;
`

const HomeCrumb = styled(Breadcrumbs.Breadcrumb)`
    cursor: pointer;
    color: ${tokens.colors.interactive.primary__resting.rgba};
`

interface ProjectBreadCrumbsProps {
	isInAdmin?: boolean
}

const ProjectBreadCrumbs = ({ isInAdmin } : ProjectBreadCrumbsProps) => {
  const { setCurrentLevel, currentLevel, setLevelText } = useContext(ViewContext)
  const { filterOptions, setFilterOptions } = useFilterOptionsContext()
  const { searchState, updateSearchState } = useSearchStateContext()
  const { filters } = searchState

  const valueInFilter = (name: string) => filters[name]
  const checkIfNameHasOptions = (name: string) => filterOptions[name] !== undefined && filterOptions[name].length > 0

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const fetchedNavigationFilters = useMemo(
    () => NAVIGATION_FILTER_NAMES_IN_ORDER.filter(name => checkIfNameHasOptions(name) || filters[name] !== undefined),
    [NAVIGATION_FILTER_NAMES_IN_ORDER, filters, checkIfNameHasOptions]
  )

  const removeNavigationFilter = index => {
    const filterToUpdate = NAVIGATION_FILTER_NAMES_IN_ORDER[index]
    setFilterOptions({})
    const updatedSearchState = updatedNavigationSearchStatePart(searchState, filterToUpdate, {})
    updateSearchState(updatedSearchState, isInAdmin)
  }

  useEffect(() => {
    if (fetchedNavigationFilters.length > 0) {
      const lastFilter = fetchedNavigationFilters[fetchedNavigationFilters.length - 2]
      const filterName = Array.isArray(filters[lastFilter]) ? filters[lastFilter][0] : filters[lastFilter]

      if(filterName) 
        setLevelText(removeEroomFromName(filterName)) 
      else
        setLevelText('')
    }
  }, [fetchedNavigationFilters])
    

  return (
    <CrumbContainer>
      <Breadcrumbs collapse={windowWidth < 700}>
        <HomeCrumb 
          as={Link} 
          to= '/'
        >
          Home
        </HomeCrumb>
        {isInAdmin && (
          <HomeCrumb 
            as={Link} 
            to='/admin'
          >
          Reported files
          </HomeCrumb>
        )}
        {fetchedNavigationFilters &&
                    fetchedNavigationFilters.length > 1 &&
                    fetchedNavigationFilters.map((name, index) => {
                      const selectedValue = valueInFilter(name)
                      const crumbText = selectedValue ? removeEroomFromName(selectedValue.toString()) : null
                      if (!crumbText) return null

                      const handleClick = e => {
                        e.preventDefault()
                        if (name !== currentLevel) {
                          setCurrentLevel(name)
                          removeNavigationFilter(index)
                        }
                      }
                      return <CrumbElement key={crumbText} crumbText={crumbText} disabled={false} onClick={handleClick} />
                    })}

        {searchState.searchString !== '' && (
          <Breadcrumbs.Breadcrumb>
            {' '}
            Search results for: <strong>{searchState.searchString}</strong>
          </Breadcrumbs.Breadcrumb>
        )}
      </Breadcrumbs>
    </CrumbContainer>
  )
}

export default ProjectBreadCrumbs
