import React from 'react'
import { Card, Icon, Typography } from '@equinor/eds-core-react'
import { ErrorObject, useErrorContext } from './ErrorContext'
import { tokens } from '@equinor/eds-tokens'

interface ErrorDisplayProps {
    error: ErrorObject
}

const ErrorDisplay = ({ error }: ErrorDisplayProps) => {
  const { setError } = useErrorContext()
  const { originalError, title, suggestedAction } = error

  const removeError = () => {
    setError(undefined)
  }

  return (
    <Card variant="danger" style={{ borderRadius: '0', paddingLeft: '40px', paddingRight: '40px' }}>
      <Card.Header>
        <Icon name="error_outlined" title="Error" size={40} color={tokens.colors.text.static_icons__secondary.rgba} />
        <Card.HeaderTitle>
          <Typography variant="h5">{title ? title : originalError?.name}</Typography>
          <Typography variant="body_short">
            {originalError?.message || 'Wait a couple of minutes and try again, or contact system administrator'}
          </Typography>
          {suggestedAction && <Typography variant="body_short">{suggestedAction}</Typography>}
        </Card.HeaderTitle>
        <Icon name="clear" title="clear" onClick={removeError} style={{ cursor: 'pointer' }} />
      </Card.Header>
    </Card>
  )
}

export default ErrorDisplay
