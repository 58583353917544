import React from 'react'

interface ExportIconProps {
  size: number;
  color: string; // Define the color prop
}

const ExportIcon: React.FC<ExportIconProps> = ({ size, color }) => {
  return (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 24 24" 
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill={color} d="M17.4,17.1c-0.6,0-1.1,0-1.7,0V20H4V4h6.9v5h4.9v1.8c0.4,0,1.4,0,2,0V8l-5.9-6H4C2.9,2,2,2.9,2,4l0,16c0,1.1,0.9,2,2,2h11.8 c1.1,0,2-0.9,2-2v-2.9C17.6,17.1,17.5,17.1,17.4,17.1z" />
      <path fill={color} d="M19.2,10.7c0,0.5,0,1,0,1.6c0,0.2,0,0.3,0,0.5c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0 s0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.6,0-1.2,0-1.8,0c-0.6,0-1.1,0-1.7,0v2c0.6,0,1.1,0,1.7,0c0.6,0,1.2,0,1.8,0 c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4c0,0.5,0,1,0,1.5L23,14 L19.2,10.7z" />
      <path fill={color} d="M14.9,13c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0h-2.9v2h2.8c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0L14.9,13 C15,13,15,13,14.9,13z" />
    </svg>
  )
}

export default ExportIcon
